<template>
  <div style="padding: 3rem 1.5rem;height: 100%;background: white;display: flex;justify-content: center;position: relative">
    <div style="width:100%;padding-top: 5rem" v-if="ApplyStatus==='pending'">
      <div style="font-size: 1.75rem;text-align: center;margin-bottom: 2rem">注册账号</div>
      <el-form ref="Login" :rules="LoginRules" :model="LoginFormData" label-position="top" label-width="5rem" style="width: 100%" class="register_form">
        <el-form-item prop="mobile">
          <template slot="label">
            <span class="label_login">手机号</span>
          </template>
          <el-input class="mobile_input" placeholder="请输入您的手机号" v-model="LoginFormData.mobile"></el-input>
        </el-form-item>
        <el-form-item prop="vcode">
          <template slot="label">
            <span class="label_login">验证码</span>
          </template>
          <div class="flex-row">
            <el-input class="mobile_input code_input" placeholder="请输入验证码" v-model="LoginFormData.vcode" ></el-input>
            <el-button class="button_login_code btn-color main_btn4" @click="GetCode" :disabled="codeStatus">{{codeText}}</el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="submit_login btn-color main_btn4" style="margin-top: 0.75rem;" @click="LoginSubmit">立即申请</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="message" v-if="mobileError">
      <i class="el-icon-warning-outline" style="color: red;font-size: 2rem"></i>
      <span>请输入正确的</span>
      <span>手机号码</span>
    </div>
    <div style="margin-top: 9rem" v-if="ApplyStatus==='success'">
      <div class="flex-col items-center justify-center">
        <img
            alt="注册成功"
            style="height: 4.375rem"
            :src="require('@/assets/succeed.png')"
        />
        <span style="font-size: 1.75rem;color: #EDA558;margin: 1.5rem 0">注册成功</span>
        <span style="font-size: 0.875rem;color: #999;">请使用PC进入麦嘉跨境金融官方网站进行相关操作</span>
        <span style="font-size: 0.875rem;color: #999;">https://www.makafin.com</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Register",
  data(){
    var  validatemobile = (rule, value, callback) => {
      if(!(/^1[3456789]\d{9}$/.test(value))){
        callback(new Error('请输入正确的手机号码格式'));
      }
      callback()
    }
    return {
      //验证码消息
      codeText:"获取验证码",
      codeStatus:false,
      //手机号错误
      mobileError:false,
      //申请状态
      ApplyStatus:'pending',
      //推荐人信息
      PData:{
        pid:'',
        makafin_source:'1',
      },
      //申请信息
      LoginFormData:{
        mobile:'',
        vcode:'',
      },
      //注册数据校验
      LoginRules:{
        mobile:[
          { required: true, message: '请输入手机号', trigger: 'blur'},
          {validator:validatemobile,trigger: 'blur'}
        ],
        vcode:[
          { required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {

  },
  methods:{
    //获取验证码
    GetCode(){
      let query=this.$utils.CacheSessionGet('query') || this.PData
      if(!(/^1[3456789]\d{9}$/.test(this.LoginFormData.mobile))){
        this.mobileError=true
        setTimeout(()=>{
          this.mobileError=false
        },800)
        return
      }
      this.codeStatus=true
      this.$api.post(`v1/api/makafin/base_account/send/sms`, {...query,...this.LoginFormData},
          res=>{
            let num = 60;
            this.codeText = `已发送（${num} s）`;
            let timeStop = setInterval(() => {
              num--;
              this.codeText = `已发送（${num} s）`;
              if (num === 0) {
                this.codeText = '获取验证码';
                this.codeStatus = false;
                clearInterval(timeStop); //清除定时器
              }
            }, 1000);
          }
      )
    },
    //登录
    LoginSubmit(){
      this.$refs['Login'].validate((valid) => {
        if (valid) {
          this.$api.post('v1/api/makafin/base_account/login/vcode',this.LoginFormData,
              res=>{
                this.ApplyStatus='success'
              })
        }
      });
    }
  },

}
</script>
<style scoped>
.code_input .el-input__inner{
 height: 100%!important;
}
.button_login_code.el-button.is-disabled{
  background: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);
  color:#333;
}
.message{
  width: 6rem;
  height: 6rem;
  position: absolute;
  left:calc(50% - 3rem);
  top:calc(50% - 3rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(0,0,0,0.5);
  border-radius: 6px;
}
.label_login{
  font-size: 1.125rem;
  color: #333;
}
.button_login_code{
  font-size: 1rem;
  line-height: 1.5;
  margin-left: 2rem;
  width: 11rem;
  color:#161616;
  border-radius: 0.5rem;
}
.submit_login{
  line-height:3.5;
  width: 100%;
  font-size: 1.125rem;
  color: #161616;
  border-radius: 0.5rem;
}
.btn-color{
  background: #FFBA56;
}
</style>
<style>
.register_form  .mobile_input .el-input__inner {
  font-size: 0.875rem;
  height: 3.5rem;
}
.register_form  .el-form-item__label{
  font-size: 1.125rem;
  padding:0 0  0.8rem!important ;
}
.register_form .el-form-item{
  margin-bottom: 2rem!important;
}
.register_form .el-form-item__error{
  font-size: 1rem;
}
</style>